import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  allowNotifications() {
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
      return;
    }
    Notification.requestPermission().then(permission => {
      this.notificationStatus = permission;
    });
  }
}
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["post"]

  connect() {
    this.updatePostPositions()
  }

  closePost(event) {
    event.preventDefault()
    event.stopPropagation()
    
    const clickedPost = event.target.closest('[data-blog-post-stack-target="post"]')
    const index = this.postTargets.indexOf(clickedPost)
    
    if (index > -1) {
      const postId = clickedPost.dataset.postId

      this.animatePostClosure(clickedPost)
      
      setTimeout(() => {
        fetch(`/blog/${postId}/mark_as_read`, {
          method: 'POST',
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
            'Content-Type': 'application/json',
          },
        })
        .then(response => {
          if (response.ok) {
            clickedPost.remove()
            this.updatePostPositions()
          } else {
            console.error('Failed to mark post as read')
            this.revertCloseAnimation(clickedPost)
          }
        })
        .catch(error => {
          console.error('Error:', error)
          this.revertCloseAnimation(clickedPost)
        })
      }, 500) // Match this with the animation duration
    }
  }

  animatePostClosure(post) {
    post.style.transition = 'opacity 500ms, transform 500ms'
    post.style.opacity = '0'
    post.style.transform = 'translateY(30px)'
    post.style.pointerEvents = 'none'
    
    // Animate other posts immediately
    this.updatePostPositions(post)
  }

  updatePostPositions(closingPost = null) {
    const visiblePosts = this.postTargets.filter(post => post !== closingPost && post.style.opacity !== '0')
    visiblePosts.forEach((post, index) => {
      post.style.transition = 'z-index 500ms, bottom 500ms, transform 500ms'
      post.style.zIndex = `${30 - index * 10}`
      post.style.bottom = `${index * 10}px`
      post.style.transform = `scale(${1 - index * 0.05})`
    })
  }

  revertCloseAnimation(post) {
    post.style.transition = 'opacity 500ms, transform 500ms'
    post.style.opacity = '1'
    post.style.transform = 'translateY(0)'
    post.style.pointerEvents = ''
    this.updatePostPositions()
  }
}

import { Controller } from "@hotwired/stimulus"
import { useIntersection } from 'stimulus-use'

import * as Bytescale from "@bytescale/sdk";

export default class extends Controller {
  static targets = ['buttons', 'speakButton']


  connect() {
    this.audioChunks = [];
    this.mediaRecorder = null;
    useIntersection(this)

    this.uploadManager = new Bytescale.UploadManager({
      apiKey: "public_12a1yz6vdnNV1DQqyBykzEMyUvpq"
    });
  }

  // appear() {
  //   this.startRecording()
  // }

  disapper() {
    this.cancelRecording()
  }

  startRecording() {

    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        this.buttonsTarget.style.display = 'flex';
        this.speakButtonTarget.style.display = 'none';

        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaRecorder.ondataavailable = event => {
          this.audioChunks.push(event.data);
        };
        this.mediaRecorder.start();
      })
      .catch(error => console.error("Error accessing microphone:", error));
  }

  stopRecording() {
    this.buttonsTarget.style.display = 'none';
    this.speakButtonTarget.style.display = 'flex';
    this.mediaRecorder.stop();
    this.mediaRecorder.onstop = async () => {
      const audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' }); // Specify MIME type
      const audioFile = new File([audioBlob], "recording.wav", { type: 'audio/wav' });
      const fileInput = document.querySelector('.voice-input'); // Adjust selector as needed
      
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(audioFile);
      fileInput.files = dataTransfer.files;

      // let fileUrl = await this.handleFileUpload(audioFile);
      // fileInput.value = fileUrl

      // once the file is set, you can submit the form
      const form = fileInput.closest('form');
      form.requestSubmit();

      this.audioChunks = []; // Reset chunks

      fileInput.closest('.modal').dispatchEvent(new CustomEvent("closeModal"));
    };
  }

  cancelRecording() {
    if(this.mediaRecorder !== null) {
      this.mediaRecorder.stop();
      this.audioChunks = [];
    }
    this.buttonsTarget.style.display = 'none';
    this.speakButtonTarget.style.display = 'flex';
  }

  async handleFileUpload(file) {
    try {
      const { fileUrl, filePath } = await this.uploadManager.upload({ data: file });
      return fileUrl
    } catch (e) {
      alert(`Error:\n${e.message}`); // Alert the error message if the upload fails
    }
  }
}
import { Controller } from "@hotwired/stimulus";
import VanillaTilt from 'vanilla-tilt';

export default class extends Controller {
  connect() {
    VanillaTilt.init(this.element, {
      max: 2,
      speed: 500,
      glare: true,
      easing: "cubic-bezier(.03,.98,.52,.99)",
    });
  }
}
import { Controller } from "@hotwired/stimulus";
import tippy from 'tippy.js';


export default class extends Controller {
  static targets = ['button', 'dropdown', 'input']

  connect() {
    this.tippyInstane = tippy(this.buttonTarget, {
      content: this.dropdownTarget.innerHTML,
      allowHTML: true,
      trigger: 'click',
      interactive: true,
      arrow: false,
    });
  }

  selectColor(event) {
    event.preventDefault();
    this.tippyInstane.hide();
    // remove classnames with 'bg-' prefix
    this.buttonTarget.className = this.buttonTarget.className.replace(/\bbg-\S+/g, event.target.dataset.colorClass);
    this.inputTarget.value = event.target.dataset.color;
  }
}


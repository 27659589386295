import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['step']

  static values = {
    step: Number
  }

  initialize() {
    this.stepValue = 0
    this.showCurrentStep()
  }

  next() {
    this.stepValue++
  }

  previous() {
    this.stepValue--
  }

  stepValueChanged() {
    this.showCurrentStep()
  }

  showCurrentStep() {
    this.stepTargets.forEach((element, index) => {
      element.classList.toggle('hidden', index != this.stepValue)
    })
  }

}
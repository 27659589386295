import { Controller } from "@hotwired/stimulus"
import * as chrono from 'chrono-node';

export default class extends Controller {
  static targets = ["dateField", "nameField", "detailsName", 'date']

  captureDetails(event) {
    let details = chrono.parse(event.currentTarget.value);

    // if(event.currentTarget.value.length != 0){ 
    //   this.detailsContainerTarget.classList.remove("hidden");
    // }else{
    //   this.detailsContainerTarget.classList.add("hidden");
    // }

    if(details[0]){
      this.dateFieldTarget.value = details[0].start.date();
      this.nameFieldTarget.value = event.currentTarget.value.replace(details[0].text, "").trim();
      // this.dateContainerTarget.classList.remove("hidden");
      this.dateTarget.innerHTML = details[0].start.date().toLocaleDateString();
      this.detailsNameTarget.innerHTML = event.currentTarget.value.replace(details[0].text, "").trim();
    }else{
      this.dateFieldTarget.value = null;
      this.nameFieldTarget.value = event.currentTarget.value;
      // this.dateContainerTarget.classList.add("hidden");
      this.dateTarget.innerHTML = null;
      this.detailsNameTarget.innerHTML = event.currentTarget.value;
    }
  }

  submit(event){
    event.preventDefault();
    event.currentTarget.requestSubmit();
    event.currentTarget.reset();
    // event.currentTarget.closest('.modal').dispatchEvent(new CustomEvent("closeModal"));
    // this.detailsContainerTarget.classList.add("hidden");
  }

}
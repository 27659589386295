import { Controller } from "@hotwired/stimulus"
import { platform } from '@todesktop/client-core'

export default class extends Controller {
  connect() {
    if (platform.todesktop.isDesktopApp()) {
      const notification = new Notification('Capturing Tasks', {  body: this.element.dataset.message });
    }
  }
}

import { Editor } from '@tiptap/core'
import StarterKit from '@tiptap/starter-kit'
import { Controller } from "@hotwired/stimulus"
import FileHandler from '@tiptap-pro/extension-file-handler'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import Placeholder from '@tiptap/extension-placeholder'
import * as Bytescale from "@bytescale/sdk";

export default class extends Controller {
  static targets = ['editor', 'boldButton', 'italicButton', 'underlineButton', 'strikeButton', 'headingButton', 'bulletListButton', 'orderedListButton', 'blockquoteButton', 'codeBlockButton', 'alignLeftButton', 'alignCenterButton', 'alignRightButton', 'linkButton', 'realInputField']

  connect() {
    this.uploadManager = new Bytescale.UploadManager({
      apiKey: "public_12a1yz6vdnNV1DQqyBykzEMyUvpq"
    });

    this.editor = new Editor({
      editorProps: {
        attributes: {
          class: 'focus:outline-none p-4 h-dvh',
        },
      },
      element: this.editorTarget,
      extensions: [
        StarterKit,
        Image,
        Link.configure({
          openOnClick: false,
        }),
        Underline,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        FileHandler.configure({
          allowedMimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],
          onDrop: this.handleFileDrop.bind(this),
          onPaste: this.handleFilePaste.bind(this),
        }),
        Placeholder.configure({
          placeholder: ({ node }) => {
            if (node.type.name === 'heading') {
              return 'What\'s the title?'
            }
            return 'Start typing or use the toolbar to format your content...'
          },
        }),
      ],
      content: this.realInputFieldTarget.value || '',
    })

    this.editor.on('update', () => {
      const html = this.editor.getHTML()
      this.realInputFieldTarget.value = html
      if (this.element.dataset.autoSave !== 'false') {
        this.realInputFieldTarget.form.requestSubmit()
      }
    })

    this.updateButtonStates()
    this.editor.on('selectionUpdate', () => this.updateButtonStates())
  }

  updateButtonStates() {
    this.toggleButtonState(this.boldButtonTarget, this.editor.isActive('bold'))
    this.toggleButtonState(this.italicButtonTarget, this.editor.isActive('italic'))
    this.toggleButtonState(this.underlineButtonTarget, this.editor.isActive('underline'))
    this.toggleButtonState(this.strikeButtonTarget, this.editor.isActive('strike'))
    this.toggleButtonState(this.bulletListButtonTarget, this.editor.isActive('bulletList'))
    this.toggleButtonState(this.orderedListButtonTarget, this.editor.isActive('orderedList'))
    this.toggleButtonState(this.blockquoteButtonTarget, this.editor.isActive('blockquote'))
    this.toggleButtonState(this.codeBlockButtonTarget, this.editor.isActive('codeBlock'))
    this.toggleButtonState(this.linkButtonTarget, this.editor.isActive('link'))
    
    this.headingButtonTargets.forEach((button, index) => {
      this.toggleButtonState(button, this.editor.isActive('heading', { level: index + 1 }))
    })

    this.toggleButtonState(this.alignLeftButtonTarget, this.editor.isActive({ textAlign: 'left' }))
    this.toggleButtonState(this.alignCenterButtonTarget, this.editor.isActive({ textAlign: 'center' }))
    this.toggleButtonState(this.alignRightButtonTarget, this.editor.isActive({ textAlign: 'right' }))
  }

  toggleButtonState(button, isActive) {
    button.classList.toggle('active-editor-button', isActive)
    button.classList.toggle('inactive-editor-button', !isActive)
  }

  setBold() {
    this.editor.chain().focus().toggleBold().run()
  }

  setItalic() {
    this.editor.chain().focus().toggleItalic().run()
  }

  setUnderline() {
    this.editor.chain().focus().toggleUnderline().run()
  }

  setStrike() {
    this.editor.chain().focus().toggleStrike().run()
  }

  setHeading(event) {
    let level = parseInt(event.currentTarget.dataset.headingLevel)
    this.editor.chain().focus().toggleHeading({ level: level }).run()
  }

  setBulletList() {
    this.editor.chain().focus().toggleBulletList().run()
  }

  setOrderedList() {
    this.editor.chain().focus().toggleOrderedList().run()
  }

  setBlockquote() {
    this.editor.chain().focus().toggleBlockquote().run()
  }

  setCodeBlock() {
    this.editor.chain().focus().toggleCodeBlock().run()
  }

  setTextAlign(event) {
    const alignment = event.currentTarget.dataset.align
    this.editor.chain().focus().setTextAlign(alignment).run()
  }

  setLink() {
    const url = window.prompt('Enter the URL')
    if (url) {
      this.editor.chain().focus().setLink({ href: url }).run()
    } else if (this.editor.isActive('link')) {
      this.editor.chain().focus().unsetLink().run()
    }
  }

  async handleFileUpload(file) {
    try {
      const { fileUrl } = await this.uploadManager.upload({ data: file });
      return fileUrl
    } catch (e) {
      console.error('Upload failed:', e);
      alert(`Error:\n${e.message}`);
    }
  }

  handleFileDrop(editor, files, pos) {
    files.forEach(async (file) => {
      const fileUrl = await this.handleFileUpload(file);
      if (fileUrl) {
        editor.chain().insertContentAt(pos, {
          type: 'image',
          attrs: { src: fileUrl },
        }).focus().run()
      }
    })
  }

  handleFilePaste(editor, files, htmlContent) {
    if (htmlContent) {
      console.log(htmlContent)
      return false
    }

    files.forEach(async (file) => {
      const fileUrl = await this.handleFileUpload(file);
      if (fileUrl) {
        editor.chain().insertContentAt(editor.state.selection.anchor, {
          type: 'image',
          attrs: { src: fileUrl },
        }).focus().run()
      }
    })
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  connect() {
    let userParams = JSON.parse(this.element.dataset.atlasUser)

    window.Atlas.call("identify", {
      userId: userParams.userId,
      name: userParams.name,
      email: userParams.email,
      userHash: userParams.userHash,
    })
  }
}

import { Editor } from '@tiptap/core'
import StarterKit from '@tiptap/starter-kit'
import { Controller } from "@hotwired/stimulus"
import Placeholder from '@tiptap/extension-placeholder'
// Add these new imports
import FileHandler from '@tiptap-pro/extension-file-handler'
import Image from '@tiptap/extension-image'
import * as Bytescale from "@bytescale/sdk"

export default class extends Controller {
  static values = {
    contentFieldId: String,
  }
  static targets = ['editor', 'menu', 'menuItems', 'menuItem', 'noResults']

  connect() {
    // Add upload manager initialization
    this.uploadManager = new Bytescale.UploadManager({
      apiKey: "public_12a1yz6vdnNV1DQqyBykzEMyUvpq"
    });

    let contentField = window.document.getElementById(this.contentFieldIdValue)

    this.editor = new Editor({
      element: this.editorTarget,
      extensions: [
        StarterKit,
        Placeholder.configure({
          placeholder: 'Type "/" for commands...',
        }),
        // Add these new extensions
        Image,
        FileHandler.configure({
          allowedMimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],
          onDrop: this.handleFileDrop.bind(this),
          onPaste: this.handleFilePaste.bind(this),
        }),
      ],
      content: contentField.value,
      editorProps: {
        attributes: {
          class: 'prose prose-sm sm:prose lg:prose-lg xl:prose-2xl focus:outline-none',
        },
      },
      onUpdate: ({ editor }) => {
        contentField.value = editor.getHTML()
      },
    })

    this.editor.commands.focus('end')
    this.menuOpen = false
    this.selectedIndex = -1
    this.menuItems = [
      { label: 'Paragraph', type: 'paragraph' },
      { label: 'Heading 1', type: 'heading1' },
      { label: 'Heading 2', type: 'heading2' },
      { label: 'Heading 3', type: 'heading3' },
      { label: 'Bullet List', type: 'bulletList' },
      { label: 'Numbered List', type: 'numberedList' },
    ]
    this.searchQuery = ''
    this.noResultsCount = 0
  }

  disconnect() {
    this.editor.destroy()
  }

  handleKeydown(event) {
    if (event.key === '/' && !this.menuOpen) {
      // Don't prevent default, allow the slash to be typed
      this.showMenu()
      this.searchQuery = '/'
    } else if (this.menuOpen) {
      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault()
          this.selectNextItem()
          break
        case 'ArrowUp':
          event.preventDefault()
          this.selectPreviousItem()
          break
        case 'Enter':
          event.preventDefault()
          if (this.selectedIndex >= 0) {
            this.selectCurrentItem()
          }
          this.hideMenu()
          break
        case 'Escape':
          event.preventDefault()
          this.hideMenu()
          break
        case 'Backspace':
          if (this.searchQuery.length <= 1) {
            this.hideMenu()
          } else {
            this.searchQuery = this.searchQuery.slice(0, -1)
            this.filterMenuItems()
          }
          break
        default:
          if (event.key.length === 1) {
            this.searchQuery += event.key
            this.filterMenuItems()
          }
      }
    }
  }

  showMenu() {
    const { view } = this.editor
    const { state } = view
    const { from } = state.selection

    const start = view.coordsAtPos(from)
    const editorRect = this.editorTarget.getBoundingClientRect()

    const top = start.bottom - editorRect.top
    const left = start.left - editorRect.left

    this.menuTarget.style.top = `${top}px`
    this.menuTarget.style.left = `${left}px`
    this.menuTarget.classList.remove('hidden')
    this.menuOpen = true
    this.selectedIndex = -1
    this.filterMenuItems()
    this.noResultsCount = 0
  }

  hideMenu() {
    this.menuTarget.classList.add('hidden')
    this.menuOpen = false
    this.selectedIndex = -1
    this.searchQuery = ''
    this.noResultsCount = 0
  }

  filterMenuItems() {
    const query = this.searchQuery.slice(1).toLowerCase() // Remove the leading '/'
    const visibleItems = this.menuItemTargets.filter(item => 
      item.textContent.toLowerCase().includes(query)
    )
    
    if (visibleItems.length === 0) {
      this.noResultsCount++
      if (this.noResultsCount >= 3) {
        this.hideMenu()
        return
      }
      this.showNoResults()
    } else {
      this.noResultsCount = 0
      this.showMenuItems(visibleItems)
    }
    
    this.selectedIndex = -1
    this.updateSelectedItem()
  }

  showMenuItems(items) {
    this.menuItemsTarget.classList.remove('hidden')
    this.noResultsTarget.classList.add('hidden')
    this.menuItemTargets.forEach(item => {
      if (items.includes(item)) {
        item.classList.remove('hidden')
      } else {
        item.classList.add('hidden')
      }
    })
  }

  showNoResults() {
    this.menuItemsTarget.classList.add('hidden')
    this.noResultsTarget.classList.remove('hidden')
  }

  updateSelectedItem() {
    const visibleItems = this.menuItemTargets.filter(item => !item.classList.contains('hidden'))
    visibleItems.forEach((item, index) => {
      if (index === this.selectedIndex) {
        item.classList.add('bg-gray-3')
      } else {
        item.classList.remove('bg-gray-3')
      }
    })
  }

  selectNextItem() {
    const visibleItems = this.menuItemTargets.filter(item => !item.classList.contains('hidden'))
    this.selectedIndex = (this.selectedIndex + 1) % visibleItems.length
    this.updateSelectedItem()
  }

  selectPreviousItem() {
    const visibleItems = this.menuItemTargets.filter(item => !item.classList.contains('hidden'))
    this.selectedIndex = (this.selectedIndex - 1 + visibleItems.length) % visibleItems.length
    this.updateSelectedItem()
  }

  selectCurrentItem() {
    const visibleItems = this.menuItemTargets.filter(item => !item.classList.contains('hidden'))
    if (this.selectedIndex >= 0 && this.selectedIndex < visibleItems.length) {
      visibleItems[this.selectedIndex].click()
    }
  }

  selectBlockType(event) {
    try {
      const blockType = event.currentTarget.dataset.type
      const { state } = this.editor
      const { from } = state.selection
      
      // Search backwards from the cursor position to find the slash
      let slashPos = from
      while (slashPos > 0) {
        const char = state.doc.textBetween(slashPos - 1, slashPos)
        if (char === '/') {
          // Found the slash, now remove everything from slash to current cursor
          this.editor.chain()
            .deleteRange({ from: slashPos - 1, to: from })
            .focus()
            .run()
          break
        }
        slashPos--
      }

      // Apply the selected block type
      switch(blockType) {
        case 'paragraph':
          this.editor.chain().focus().setParagraph().run()
          break
        case 'heading1':
          this.editor.chain().focus().setHeading({ level: 1 }).run()
          break
        case 'heading2':
          this.editor.chain().focus().setHeading({ level: 2 }).run()
          break
        case 'heading3':
          this.editor.chain().focus().setHeading({ level: 3 }).run()
          break
        case 'bulletList':
          this.editor.chain().focus().toggleBulletList().run()
          break
        case 'numberedList':
          this.editor.chain().focus().toggleOrderedList().run()
          break
      }
      this.hideMenu()
    } catch (error) {
      console.error('Error in selectBlockType:', error)
    }
  }

  // Add these new methods for handling file uploads
  async handleFileUpload(file) {
    // Create a temporary ID for this upload
    const uploadId = `upload-${Date.now()}`
    
    // Insert a placeholder image immediately
    this.editor.chain().insertContent({
      type: 'image',
      attrs: {
        src: URL.createObjectURL(file),
        'data-upload-id': uploadId,
        class: 'opacity-50 transition-opacity duration-200',
      },
    }).run()

    // Add loading overlay
    const img = this.editorTarget.querySelector(`img[data-upload-id="${uploadId}"]`)
    if (img) {
      const wrapper = document.createElement('div')
      wrapper.className = 'relative inline-block'
      img.parentNode.insertBefore(wrapper, img)
      wrapper.appendChild(img)
      
      const overlay = document.createElement('div')
      overlay.className = 'absolute inset-0 flex items-center justify-center bg-gray-900/10 rounded'
      overlay.innerHTML = `
        <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      `
      wrapper.appendChild(overlay)
    }

    try {
      const { fileUrl } = await this.uploadManager.upload({ data: file });
      
      // Replace the placeholder with the actual image
      const imgs = this.editorTarget.querySelectorAll(`img[data-upload-id="${uploadId}"]`)
      imgs.forEach(img => {
        img.src = fileUrl
        img.removeAttribute('data-upload-id')
        img.classList.remove('opacity-50')
        
        // Remove the loading overlay
        if (img.parentNode.classList.contains('relative')) {
          const wrapper = img.parentNode
          const parent = wrapper.parentNode
          parent.insertBefore(img, wrapper)
          wrapper.remove()
        }
      })
      
      return fileUrl
    } catch (e) {
      console.error('Upload failed:', e)
      
      // Remove the placeholder on error
      const imgs = this.editorTarget.querySelectorAll(`img[data-upload-id="${uploadId}"]`)
      imgs.forEach(img => {
        if (img.parentNode.classList.contains('relative')) {
          img.parentNode.remove()
        } else {
          img.remove()
        }
      })
      
      alert(`Error uploading image:\n${e.message}`)
    }
  }

  handleFileDrop(editor, files, pos) {
    files.forEach(async (file) => {
      await this.handleFileUpload(file)
    })
  }

  handleFilePaste(editor, files, htmlContent) {
    if (htmlContent) {
      return false
    }

    files.forEach(async (file) => {
      await this.handleFileUpload(file)
    })
  }

  // ... rest of the code remains the same
}

import { Controller } from "@hotwired/stimulus"
import Intercom from '@intercom/messenger-js-sdk';

export default class extends Controller {
  initialize() {
    Intercom({
      app_id: 'vkf2ggfz',
      user_id: this.element.dataset.user_id,
      name: this.element.dataset.name,
      email: this.element.dataset.email,
      created_at: this.element.dataset.created_at, 
      custom_launcher_selector:'#open-intercom',
      alignment: 'right'
    });
  }
}
import { Controller } from "@hotwired/stimulus"
import anime from 'animejs/lib/anime.es.js';
import {install} from '@github/hotkey'

export default class extends Controller {
  static targets = ['title', 'text', 'nextButton']
  
  connect() {
    this.animateElements()


  }

  animateElements() {
    // First, remove the 'hidden' class and set initial states
    [this.titleTarget, this.textTarget, this.nextButtonTarget].forEach(el => {
      el.classList.remove('hidden');
      el.style.opacity = 0;
      el.style.transform = 'translateY(20px)';
    });

    // Then animate
    anime.timeline({
      easing: 'easeOutQuad',
      duration: 800
    })
    .add({
      targets: this.titleTarget,
      opacity: 1,
      translateY: 0,
    })
    .add({
      targets: this.textTarget,
      opacity: 1,
      translateY: 0,
    })
    .add({
      targets: this.nextButtonTarget,
      opacity: 1,
      translateY: 0,
    }, '-=600')
  }
}
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener("mouseover", () => {
      document.getElementById("taskActionsId").value = this.element.dataset.taskId;
    });
  }

    // this.element.addEventListener("mouseout", () => {
    //   document.getElementById("taskActionsId").value = null;
    // });
  openTask(event){
    // find the closeset a tag to the event target
    let closestA = event.target.closest('a');
    if (closestA && closestA.classList.contains('preview-task-button')) return;

    this.element.querySelector('.view-task-button').click();
  }
}

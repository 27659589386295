import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    console.log(!("Notification" in window));
    if (!("Notification" in window)) {
      console.log("This browser does not support notifications.");
      return;
    }

    try {
      const notification = new Notification('Capturing Tasks', {
        body: this.element.dataset.message || 'New task captured'
      });
      console.log('Notification created:', notification);
    } catch (error) {
      console.error('Error creating notification:', error);
    }
  }
}

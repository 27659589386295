import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['dropdownContainer']

  connect(){
    this.referenceElement = this.element.firstChild
    this.floatingElement = this.element.lastChild
  }

  toggle(event) {
    event.preventDefault()
    event.stopPropagation()
    
    if (this.dropdownContainerTarget.style.opacity === '0' || !this.dropdownContainerTarget.style.opacity) {
      this.showDropdown()
    } else {
      this.hideDropdown()
    }


    // make clicking outside of the dropdown hide it
    document.addEventListener('click', (event) => {
      if (!this.dropdownContainerTarget.contains(event.target)) {
        this.hideDropdown()
      }
    })
  }

  showDropdown() {
    // Show dropdown
    this.dropdownContainerTarget.style.display = 'block'
    // Wait a tick for the display change to take effect
    requestAnimationFrame(() => {
      Object.assign(this.dropdownContainerTarget.style, {
        opacity: '1',
        transform: 'translateY(0)',
        transition: 'opacity 150ms ease-out, transform 150ms ease-out'
      })
    })
  }

  hideDropdown() {
    // Hide dropdown
    Object.assign(this.dropdownContainerTarget.style, {
      opacity: '0',
      transform: 'translateY(-8px)',
      transition: 'opacity 150ms ease-out, transform 150ms ease-out'
    })
    // Wait for animation to finish before setting display none
    this.dropdownContainerTarget.addEventListener('transitionend', () => {
      this.dropdownContainerTarget.style.display = 'none'
    }, { once: true })
  }

  // connect() {
  //   this.tippyInstane = tippy(this.buttonTarget, {
  //     content: this.dropdownTarget.innerHTML,
  //     allowHTML: true,
  //     trigger: 'click',
  //     interactive: true,
  //     arrow: false,
  //     placement: 'bottom-end',
  //     onTrigger: (instance, event) => {
  //       event.preventDefault(); 
  //       event.stopPropagation()
  //     },
  //     hideOnClick: 'false',
  //   });
  // }
}


import { Controller } from "@hotwired/stimulus"
import anime from 'animejs/lib/anime.es.js';

export default class extends Controller {
  static targets = ['logo', 'title', 'subtitle', 'button']
  
  connect() {
    this.animateElements()
  }

  animateElements() {
    // First, remove the 'hidden' class
    [this.logoTarget, this.titleTarget, this.subtitleTarget, this.buttonTarget].forEach(el => {
      el.classList.remove('hidden');
      el.style.opacity = 0;
      el.style.transform = 'translateY(20px)';
    });

    // Then animate
    anime.timeline({
      easing: 'easeOutQuad',
      duration: 800
    })
    .add({
      targets: this.logoTarget,
      opacity: 1,
      translateY: 0,
    })
    .add({
      targets: this.titleTarget,
      opacity: 1,
      translateY: 0,
    }, '-=600')
    .add({
      targets: this.subtitleTarget,
      opacity: 1,
      translateY: 0,
    }, '-=700')
    .add({
      targets: this.buttonTarget,
      opacity: 1,
      translateY: 0,
      scale: [0.9, 1],
    }, '-=700')
  }
}
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "hoursLost", "hoursLostDisplay", "hourlyValue", "potentialGain", "hoursReclaimed", "roi" ]

  connect() {
    this.calculate()
  }

  calculate() {
    const hoursLost = parseInt(this.hoursLostTarget.value)
    const hourlyValue = parseInt(this.hourlyValueTarget.value)
    
    const monthlyLoss = hoursLost * 4 * hourlyValue
    const hyperaideGain = monthlyLoss * 0.5 // Assuming Hyperaide helps reclaim 50% of lost time
    const hyperaideCost = 30
    const roi = hyperaideGain - hyperaideCost
    
    this.hoursLostDisplayTarget.textContent = `${hoursLost} hours`
    this.potentialGainTarget.textContent = `$${hyperaideGain.toFixed(2)}`
    this.hoursReclaimedTarget.textContent = hoursLost * 2
    this.roiTarget.textContent = `$${roi.toFixed(2)}`
  }
}
import { Controller } from "@hotwired/stimulus"
import {
  computePosition,
  flip,
  shift,
  offset,
} from 'https://cdn.jsdelivr.net/npm/@floating-ui/dom@1.6.3/+esm';


export default class extends Controller {
  static targets = ['button', 'dropdown', 'input', 'time', 'error']

  connect() {
    this.update_dropdown()
  }

  changeTime(event) {
    let userInput = event.currentTarget.value

    // if it contains 'm' get the number and multiply by 60
    // if it contains 'h' get the number and multiply by 3600

    if (userInput.includes('h')) {
      userInput = parseInt(userInput) * 3600
    } else {
      userInput = parseInt(userInput) * 60
    }

    // if its greater than 8 hours, set it to 8 hours
    if (userInput > 28800) {
      userInput = 28800
      this.errorTarget.innerText = 'If you need more than 8 hours, break it down into smaller tasks!'
      this.errorTarget.style.display = 'block'
    }else {
      this.errorTarget.style.display = 'none'
    }

    // change the time display to the user input. Time display is in format '15 minutes' or '2 hours'
    if (userInput < 3600) {
      this.timeTarget.innerText = `${userInput/60} minutes`
    }else {
      this.timeTarget.innerText = `${userInput/3600} hours`
    }
    // this.inputTarget.value = event.currentTarget.dataset.priority
    // this.inputTarget.form.requestSubmit()
    // this.dropdownTarget.style.display = 'none'

    this.inputTarget.value = userInput
  }

  submitForm(event) {
    this.inputTarget.form.requestSubmit()
    this.dropdownTarget.style.display = 'none'
  }

  toggle(event){
    event.preventDefault()
    event.stopPropagation()
    // close all other dropdowns
    document.querySelectorAll('.dropdown').forEach(dropdown => {
      if (dropdown !== this.dropdownTarget) {
        dropdown.style.display = 'none'
      }
    })

    event.stopPropagation()
    this.dropdownTarget.style.display = 'block'
    this.update_dropdown()

    // make clicks outside the dropdown close it
    document.addEventListener('click', this.closeDropdown)
  }

  closeDropdown = (event) => {
    if (!this.element.contains(event.target)) {
      this.dropdownTarget.style.display = 'none'
      document.removeEventListener('click', this.closeDropdown)
    }
  }

  update_dropdown() {
    computePosition(this.buttonTarget, this.dropdownTarget, {
      placement: 'bottom',
      middleware: [
        offset(6),
        flip(),
        shift({padding: 5}),
      ],
    }).then(({x, y}) => {
      Object.assign(this.dropdownTarget.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });

    // wait 1 second and focus on the input
    setTimeout(() => {
      this.dropdownTarget.querySelector('input').focus()
    }, 100)
  }
}


import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    startTime: String,
    endTime: String,
    previousTime: { type: String, default: "00:00:00" }  // Add this line
  }
  
  connect() {
    this.startTimer();
  }

  startTimer() {
    const updateTimer = () => {
      const now = new Date();
      const startTime = new Date(this.startTimeValue);
      let elapsed = now - startTime;
      
      // Add previously logged time
      const [prevHours, prevMinutes, prevSeconds] = this.previousTimeValue.split(':').map(Number);
      elapsed += (prevHours * 3600 + prevMinutes * 60 + prevSeconds) * 1000;
      
      const hours = Math.floor(elapsed / (1000 * 60 * 60));
      const minutes = Math.floor((elapsed % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((elapsed % (1000 * 60)) / 1000);
      
      const formattedTime = [
        hours.toString().padStart(2, '0'),
        minutes.toString().padStart(2, '0'),
        seconds.toString().padStart(2, '0')
      ].join(':');
      
      this.element.textContent = formattedTime;
    };

    updateTimer(); // Update immediately
    this.timerInterval = setInterval(updateTimer, 1000);
  }

  disconnect() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  }
}

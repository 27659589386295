import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['button', 'actionButtons']

  connect() {
    console.log('RecordShortcutController#connect');
  }

  record() {
    this.buttonTarget.innerText = 'Recording...';
    this.actionButtonsTarget.style.display = 'flex';
    this.keydownHandler = this.handleKeydown.bind(this); // Bind and store the handler
    document.addEventListener('keydown', this.keydownHandler);
  }

  handleKeydown(event) {
    event.preventDefault();
    const keyCombination = this.formatKey(event);
    this.displayKeys(keyCombination);
    this.element.dataset.keyCombination = keyCombination;
  }

  formatKey(event) {
    let keys = [];

    // Check for modifier keys
    if (event.ctrlKey && !keys.includes('Control')) keys.push('Control');
    if (event.shiftKey && !keys.includes('Shift')) keys.push('Shift');
    if (event.altKey && !keys.includes('Alt')) keys.push('Alt');
    if (event.metaKey && !keys.includes('Meta')) keys.push('Meta');


    if (!keys.includes(event.code)) {
      keys.push(event.code.replace(/^Key|Digit/, ''));
    }

    // Join all parts to form a W3C-compliant key identifier
    return keys.join('+');
  }

  displayKeys(keys) {
    // parse keys into an array
    this.buttonTarget.querySelectorAll('.shortcut').forEach(shortcut => shortcut.remove());
    this.buttonTarget.innerText = '';

    keys = keys.split('+');
    keys.forEach(key => {
      // create an element with class 'shortcut' and innerText equal to key
      const shortcut = document.createElement('div');
      shortcut.classList.add('shortcut');
      shortcut.innerText = key;
      this.buttonTarget.appendChild(shortcut);
    });
  }

  saveShortcut() {
    const input = document.getElementById(this.element.dataset.inputId);
    input.value = this.element.dataset.keyCombination;
    input.form.submit();
  }

  cancelRecord() {
    this.buttonTarget.innerText = 'Change';
    this.actionButtonsTarget.style.display = 'none';

    // stop listening for keydown events
    document.removeEventListener('keydown', this.keydownHandler);
  }
}


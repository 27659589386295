import { Controller } from "@hotwired/stimulus"
import {enter, leave, toggle} from 'el-transition'

export default class extends Controller {
  static targets = ['dropdown']

  toggle(event) {
    // event.preventDefault()
    // event.stopPropagation();

    // if click is inside the dropdown, don't close it
    if(this.dropdownTarget.contains(event.target)){
      event.stopPropagation();
      return
    }
    
    // close other dropdowns
    document.querySelectorAll('.dropdown').forEach((dropdown) => {
      if(dropdown != this.dropdownTarget){
        leave(dropdown)
      }
    })

    event.preventDefault()
    event.stopPropagation();

    toggle(this.dropdownTarget)

    document.addEventListener('click', (event) => {
      if (!this.element.contains(event.target)) {
        leave(this.dropdownTarget)
      }
    })

  }
}

import { Controller } from "@hotwired/stimulus"
import { useIntersection } from "stimulus-use"

export default class extends Controller {
  static targets = ['form']

  connect() {
    useIntersection(this, {
      once: true,
      threshold: 0.1,
      rootMargin: '0px 0px -100px 0px'
    })
  }

  appear(){
    this.scrollToBottom()
  }

  scrollToBottom() {
    const messagesContainer = this.element.querySelector('#messages')
    if (messagesContainer) {
      messagesContainer.scrollTop = messagesContainer.scrollHeight
    }
  }

  submit(event) {
    event.preventDefault()
    this.formTarget.requestSubmit()
    this.formTarget.reset()
  }

  handleKeydown(event) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.submit(event)
    }
  }
}

import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto'

export default class extends Controller {

  connect() {
     new Chart(this.element, {
      type: 'line',
      data: {
        labels: this.element.dataset.chartLabels.split(','),
        datasets: [{
          label: '# of Users',
          data: this.element.dataset.chartData.split(',').map(Number),
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });
  }
}

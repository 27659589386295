import { Controller } from "@hotwired/stimulus"
import anime from 'animejs/lib/anime.es.js';

export default class extends Controller {

  connect() {
    
  }

  open(event) {
    this.preventDefaultBehavior(event);
    this.handleExistingModal();
    
    const modal = this.element;
    this.showModal(modal);
    this.setupModalInteractions(modal);
  }

  /**
   * Prevents default event behavior and propagation
   */
  preventDefaultBehavior(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  /**
   * Handles any existing open modal before showing a new one
   */
  handleExistingModal() {
    const openModal = document.querySelector('.modal.open, .slideover.open');
    
    if (openModal) {
      this.closeModal(openModal, { clearForm: true, closeOverlay: false });
    } else {
      this.showOverlay();
    }
  }

  /**
   * Shows the overlay with a fade-in animation
   */
  showOverlay() {
    const overlay = this.overlayTarget;
    overlay.style.display = "block";
    requestAnimationFrame(() => overlay.style.opacity = 1);
  }

  /**
   * Displays the modal with appropriate animations
   */
  showModal(modal) {
    modal.style.display = "block";
    
    requestAnimationFrame(() => {
      if (modal.classList.contains('slideover')) {
        modal.style.opacity = 1;
        modal.style.transform = 'translateX(0)';
      } else {
        modal.style.opacity = 1;
        modal.style.transform = 'translateX(-50%) scale(1)';
      }
    });

    modal.classList.add('open');
    document.body.style.overflow = 'hidden';
  }

  /**
   * Sets up modal interactions including focus, keyboard events, and click handlers
   */
  setupModalInteractions(modal) {
    this.setupInputHandling(modal);
    this.setupClickHandlers(modal);
  }

  /**
   * Handles input focus and keyboard events within the modal
   */
  setupInputHandling(modal) {
    const inputs = modal.querySelectorAll('input, textarea');
    if (!inputs.length) return;

    const autofocusInput = modal.querySelector('.autofocus-input');
    if (autofocusInput) {
      autofocusInput.focus();
    }

    inputs.forEach(input => {
      input.addEventListener('keydown', event => {
        if (event.key === 'Escape') {
          this.closeModal(modal);
        }
      });
    });
  }

  /**
   * Sets up click handlers for modal interaction
   */
  setupClickHandlers(modal) {
    // Prevent clicks inside modal from closing it
    modal.onclick = event => event.stopPropagation();

    // Close modal when clicking outside
    window.onclick = event => {
      if (event.target !== modal) {
        this.closeModal(modal);
      }
    };
  }

  close() {
    // this.element.parentElement.complete = false;
    this.element.remove();
    let modalOverlay = document.getElementById('modal-overlay');
    modalOverlay.style.display = "none";
  }
}

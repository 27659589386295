import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  scrollDown(){
    const targetSection = document.querySelector('#about'); // Replace with your section's ID
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: 'smooth' });
    }
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  toggle() {
    // if html element has class dark
    // remove class dark and set local storage to false
    // else
    // add class dark and set local storage to true

    if(document.documentElement.classList.contains('dark')) {
      document.documentElement.classList.remove('dark')
      localStorage.setItem('dark', 'false')
    } else {
      document.documentElement.classList.add('dark')
      localStorage.setItem('dark', 'true')
    }
  }
}

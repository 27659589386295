import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    copy: String
  }

  copy() {
    const buttonText = this.element.querySelector('.button-text');
    const originalText = buttonText.textContent;
    
    navigator.clipboard.writeText(this.copyValue);
    buttonText.textContent = 'Copied!';
    
    setTimeout(() => {
      buttonText.textContent = originalText;
    }, 3000);
  }
}

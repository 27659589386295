import { Controller } from "@hotwired/stimulus"
import * as chrono from 'chrono-node';
import moment from 'moment';

export default class extends Controller {
  static targets = ["date", "dateField", "dateContainer"]
  
  getDate(event){
    let date = chrono.parseDate("plan task for " + event.currentTarget.value, new Date(), {forwardDate: true});
    if(date){
      // this.dateContainerTarget.classList.remove("hidden");
      // this.dateTarget.innerHTML = date.toLocaleDateString();
      this.dateTarget.innerHTML = moment(date).format('dddd Do of MMMM');
      this.dateFieldTarget.value = date;
    }else{
      // this.dateContainerTarget.classList.add("hidden");
      this.dateTarget.innerHTML = ''
      this.dateFieldTarget.value = null;
    }
  }
}

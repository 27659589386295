import { Controller } from "@hotwired/stimulus"
import {
  computePosition,
  flip,
  shift,
  offset,
} from 'https://cdn.jsdelivr.net/npm/@floating-ui/dom@1.6.3/+esm';


export default class extends Controller {
  static targets = ['button', 'dropdown', 'input']

  connect() {
    this.update_dropdown()
  }

  changePriority(event) {
    this.inputTarget.value = event.currentTarget.dataset.priority
    this.inputTarget.form.requestSubmit()
    this.dropdownTarget.style.display = 'none'
  }



  toggle(event){
    // close all other dropdowns
    document.querySelectorAll('.dropdown').forEach(dropdown => {
      if (dropdown !== this.dropdownTarget) {
        dropdown.style.display = 'none'
      }
    })

    event.stopPropagation()
    this.dropdownTarget.style.display = 'block'
    this.update_dropdown()

    // make clicks outside the dropdown close it
    document.addEventListener('click', this.closeDropdown)
  }

  closeDropdown = (event) => {
    if (!this.element.contains(event.target)) {
      this.dropdownTarget.style.display = 'none'
      document.removeEventListener('click', this.closeDropdown)
    }
  }

  update_dropdown() {
    computePosition(this.buttonTarget, this.dropdownTarget, {
      placement: 'bottom',
      middleware: [
        offset(6),
        flip(),
        shift({padding: 5}),
      ],
    }).then(({x, y}) => {
      Object.assign(this.dropdownTarget.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
  }
}


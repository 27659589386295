import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // static targets = ['menu']

  toggle(event) {
    event.preventDefault()
    event.stopPropagation()

    // close other menus
    document.querySelectorAll('.task-context-menu').forEach((menu) => {
      if(menu != this.menuTarget){
        menu.style.opacity = 0
        menu.style.transform = 'scale(0.7)'
        menu.classList.add('hidden')
      }
    })

    // toggle menu at mouse position
    let menu = document.getElementById(`context-menu-${event.currentTarget.dataset.taskId}`)
    document.getElementById("taskActionsId").value = event.currentTarget.dataset.taskId

    menu.style.left = `${event.clientX + 5}px`
    menu.style.top = `${event.clientY + 5}px`

    // add event listener to close menu when click is outside
    document.addEventListener('click', (event) => {
      if (!menu.contains(event.target)) {
        this.hideMenu(menu)
      }
    })

    this.showMenu(menu)
  }

  showMenu(menu) {
    document.body.style.pointerEvents = 'none'
    menu.style.pointerEvents = 'all'
    menu.classList.remove('hidden')

    menu.style.opacity = 1
    menu.style.transform = 'scale(1)'
  }

  hideMenu(menu) {
    document.body.style.pointerEvents = 'all'
    menu.style.pointerEvents = 'none'
    menu.classList.add('hidden')

    menu.style.opacity = 0
    menu.style.transform = 'scale(0.7)'
  }
}


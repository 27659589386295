import { Controller } from "@hotwired/stimulus";
import { Calendar } from '@fullcalendar/core';
import rrulePlugin from '@fullcalendar/rrule'
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';

export default class extends Controller {
  connect() {
    let calendar = new Calendar(this.element, {
      plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, rrulePlugin ],
      initialView: 'timeGridWeek',
      firstDay: 1,
      editable: true,
      eventDrop: this.handleEventChange,
      eventResize: this.handleEventChange,
      drop: this.handleDrop,
      droppable: true,
      allDaySlot: false,
      slotDuration: '00:15:00', // Keep finer granularity
      slotLabelInterval: '01:00:00', // Label every hour
      // headerToolbar: false,
      nowIndicator: true,
      events: JSON.parse(this.element.dataset.tasks),
      height: '100vh',
      eventMouseEnter: function(info) {
        console.log(info.event.id);
        document.getElementById("taskActionsId").value = info.event.id;
      },
      // eventDidMount: function(arg) {
      //   if (arg.event.extendedProps && arg.event.extendedProps.bgColor) {
      //     arg.el.style.setProperty('--event-bg-color', arg.event.extendedProps.bgColor);
      //   }
        
      //   return { html: arg.event.title };
      // },
    });
    calendar.render();

    new Draggable(document.querySelector('#calendar-tasks'), {
      itemSelector: '.task'
    });

    // listen for a event to rerender the calendar
    this.element.addEventListener('move-event', (event) => {
      let movedEvent = calendar.getEventById(event.detail.task_id);
      if(event.detail.start && event.detail.end){
        movedEvent.setDates(event.detail.start, event.detail.end);
      }else{
        movedEvent.remove()
      }
    });
  }

  handleEventChange(event){
    if (event.el.classList.contains('event')) {
      const eventData = {
        id: event.event.id,
        start_time: event.event.start.toISOString(),
        end_time: event.event.end.toISOString()
      };

      // Retrieve the CSRF token from the meta tag
      const csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");

      // Make a POST request to the server with the updated event data
      fetch(`/events/${event.event.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        },
        body: JSON.stringify(eventData)
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to update event');
        }
      })
      .catch(error => {
        alert('Error updating event: ' + error);
      });
    }else{
      const eventData = {
        id: event.event.id,
        start: event.event.start.toISOString(),
        end: event.event.end.toISOString()
      };

      // Retrieve the CSRF token from the meta tag
      const csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");

      // Make a POST request to the server with the updated event data
      fetch(`/tasks/${event.event.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        },
        body: JSON.stringify(eventData)
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to update event');
        }
      })
      .catch(error => {
        alert('Error updating event: ' + error);
      });
    }
  }

  handleDrop(event){
    console.log(event);
    const eventData = {
      id: event.draggedEl.dataset.taskId,
      start: event.dateStr
    };

    // Retrieve the CSRF token from the meta tag
    const csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");

    // Make a POST request to the server with the updated event data
    fetch(`/tasks/${event.draggedEl.dataset.taskId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify(eventData)
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to update event');
      }
    })
    .catch(error => {
      alert('Error updating event: ' + error);
    });
  }
}


import { Controller } from "@hotwired/stimulus"
import anime from 'animejs/lib/anime.es.js';
import {install} from '@github/hotkey'

export default class extends Controller {
  static targets = ['title', 'text', 'button', 'shortcutHint', 'taskList', 'nextButton', 'suggestion']
  
  connect() {
    this.animateElements()

    // Remove all existing hotkey instances
    document.querySelectorAll('[data-hotkey]').forEach(el => {
      if (el.hotkeyInstalled) {
        el.hotkeyInstalled.uninstall();
      }
    });

    // Install new hotkey instances
    document.querySelectorAll('[data-hotkey]').forEach(el => {
      el.hotkeyInstalled = install(el);
    });

    // if task list gets a element added to it, make the next button visible and animate it in.
    this.taskListObserver = new MutationObserver(this.handleTaskListMutation.bind(this));
    this.taskListObserver.observe(this.taskListTarget, { childList: true });
  }

  handleTaskListMutation(mutations) {
    for (let mutation of mutations) {
      if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
        this.showNextButton();
        break;
      }
    }
  }

  showNextButton() {
    if (this.nextButtonTarget.classList.contains('hidden')) {
      this.nextButtonTarget.classList.remove('hidden');
      anime({
        targets: this.nextButtonTarget,
        opacity: 1,
        translateY: 0,
        duration: 800,
        easing: 'easeOutQuad'
      });
    }
  }

  animateElements() {
    // First, remove the 'hidden' class and set initial states
    [this.titleTarget, this.textTarget, this.suggestionTarget, this.buttonTarget, this.shortcutHintTarget].forEach(el => {
      el.classList.remove('hidden');
      el.style.opacity = 0;
      el.style.transform = 'translateY(20px)';
    });

    // Then animate
    anime.timeline({
      easing: 'easeOutQuad',
      duration: 800
    })
    .add({
      targets: this.titleTarget,
      opacity: 1,
      translateY: 0,
    })
    .add({
      targets: this.textTarget,
      opacity: 1,
      translateY: 0,
    })
    .add({
      targets: this.suggestionTarget,
      opacity: 1,
      translateY: 0,
    })
    .add({
      targets: this.buttonTarget,
      opacity: 1,
      translateY: 0,
    }, '-=600')
    .add({
      targets: this.shortcutHintTarget,
      opacity: 1,
      translateY: 0,
    }, '-=600')
  }
}
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {

    this.element.animate(
      [
        { transform: "translateY(50%)", opacity: 0},
        { transform: "translateY(0)", opacity: 1}
      ],
      {
        duration: 500,
        easing: "ease-out"
      },
    );

    // remove element after 5 seconds
    setTimeout(() => {
      this.element.animate(
        [
          { opacity: 1},
          { opacity: 0},
        ],
        {
          duration: 500,
          easing: "ease-out"
        },
      ).onfinish = () => {
        this.element.remove();
      }

    }, 5000)
  }
}

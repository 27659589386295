import { Controller } from "@hotwired/stimulus"
import JSConfetti from 'js-confetti'

export default class extends Controller {
  connect() {
    // Create a MutationObserver to observe changes in the DOM
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'childList') {
          const hasTasks = this.element.querySelector('.task');
          const emptyMessage = document.querySelector('#empty-state');

          if (hasTasks !== null) {
            // If there are children and the empty message is present, remove the message
            emptyMessage.classList.add('hidden');
          } else {
            // If there are no children and the empty message is hidden, show the message
            emptyMessage.classList.remove('hidden');

            if(this.element.id === 'inbox-tasks' && document.body.dataset.confettiOnInboxZero === '1'){
              const jsConfetti = new JSConfetti()

              jsConfetti.addConfetti({
                emojis: ['🌈', '⚡️', '💥', '✨', '💫', '🌸'],
              })
            }
          }
        }
      });
    });

    // Configuration of the observer:
    const config = { childList: true, subtree: true };

    // Start observing the target node for configured mutations
    observer.observe(this.element, config);
  }
}
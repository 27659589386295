import { Controller } from "@hotwired/stimulus";
import {uninstall, install  } from '@github/hotkey'
import * as Sentry from "@sentry/browser";

export default class extends Controller {
  connect() {
    function getCookie(name) {
      let cookieArray = document.cookie.split(';');
      for(let i = 0; i < cookieArray.length; i++) {
        let cookiePair = cookieArray[i].split('=');
        if(name == cookiePair[0].trim()) {
          return decodeURIComponent(cookiePair[1]);
        }
      }
      return null;
    }

    // Get the 'user' cookie
    let userCookie = getCookie('user');
    if (userCookie) {
      // Parse the JSON value of the cookie
      let userObject = JSON.parse(userCookie);
      Sentry.setUser({
        email: userObject.email,
      });
    }

    Sentry.init({
      dsn: "https://037e2479f8c10c9e86a41f7944de4b65@o4506792687763456.ingest.us.sentry.io/4506792688484352"
    });

    const feedback = Sentry.feedbackIntegration({
          colorScheme: "light",
          showName: false,
          autoInject: false,
          showBranding: false,
          // on form open remove all hotkey event listeners
          onFormOpen: () => {
            for (const el of document.querySelectorAll('[data-hotkey]')) {
              uninstall(el)
            }
          },
          // on form close add all hotkey event listeners
          onFormClose: () => {
            for (const el of document.querySelectorAll('[data-hotkey]')) {
              install(el)
            }
          },
        })

    feedback.attachTo(document.querySelector('#report-bug-button'));

    Sentry.addIntegration(feedback);
  }
}